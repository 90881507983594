import React from 'react';
import { Link } from 'gatsby';

import { Container, Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { WaveUpSVG } from '../../../WaveSVGs/WaveUpSVG';
import { ProductCard } from './ProductCard';
import { LogoLeftCtaCard } from '../../../General/LogoLeftCtaCard';

const useStyles = makeStyles((theme) => ({
	cont: {
		padding: '2rem 0 4rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '2rem 0',
		},
		// [theme.breakpoints.down('xs')]: {
		// 	padding: '8rem 0',
		// },
	},
	header: {
		color: theme.white,
		textAlign: 'center',
		padding: '4rem 0',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
		},
	},
}));

const ProductCards = ({
	productCardBackground,
	productHeader,
	productCards,
	productCtaLink,
	productCtaText,
	logoLeftCta,
}) => {
	const classes = useStyles();

	const {
		ctaBackground,
		_rawCtaHeader,
		ctaSubheader,
		ctaText,
		ctaLink,
		internalLink,
		ctaLogo,
	} = logoLeftCta;

	return (
		<>
			<div
				style={{
					backgroundImage: `url(${productCardBackground?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}
				className={classes.cont}>
				<Container>
					<Typography variant='h2' className={classes.header}>
						{productHeader}
					</Typography>
					<Grid
						container
						spacing={2}
						style={{ paddingBottom: '4rem' }}
						justifyContent='center'>
						{productCards.map((card, index) => (
							<ProductCard key={index} card={card} />
						))}
					</Grid>
					<Grid
						container
						justifyContent='center'
						alignItems='center'
						style={{ paddingBottom: '4rem' }}>
						<Link to={`/${productCtaLink}`} style={{ textDecoration: 'none' }}>
							<Button color='secondary' variant='contained' size='large'>
								{productCtaText}
							</Button>
						</Link>
					</Grid>
					<LogoLeftCtaCard
						background={ctaBackground}
						header={_rawCtaHeader}
						subheader={ctaSubheader}
						ctaText={ctaText}
						ctaLink={ctaLink}
						internalLink={internalLink}
						ctaLogo={ctaLogo}
					/>
				</Container>
			</div>
			<WaveUpSVG height='213' width='100%' fill='#FFFFFF' />
		</>
	);
};

export default ProductCards;
