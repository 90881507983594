import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Grid,
	Typography,
	Card,
	CardContent,
	makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		padding: '0px',
		borderRadius: '20px',
		minHeight: '445px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		// maxWidth: '289px',
		position: 'relative',
		// paddingTop: '2.75rem',
		// [theme.breakpoints.down('xs')]: {
		// 	maxWidth: '100% !important',
		// 	marginLeft: '0px',
		// 	marginRight: '0px',
		// 	width: '100%',
		// },
		// [theme.breakpoints.down('sm')]: {
		// 	maxWidth: '263px',
		// },
		[theme.breakpoints.down('md')]: {
			minHeight: 'auto',
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: '500px',
		},

		// [theme.breakpoints.up('lg')]: {
		// 	maxWidth: '289px',
		// },
		transition: 'transform 0.25s',
		// boxShadow:
		// 	'0 13px 27px -5px rgba(50, 50, 93, 0), 0 8px 16px -8px rgba(0, 0, 0, 0)',
	},
	title: {
		fontSize: '1.25rem',
		fontWeight: 600,
		textAlign: 'left',
		color: '#183B56',
		marginLeft: '.5rem',
		lineHeight: 1.3,
		[theme.breakpoints.down('xs')]: {
			marginLeft: '1rem',
		},
	},
	pos: {
		textAlign: 'left',
		lineHeight: '22px',
	},
}));

export const ProductCard = ({ card }) => {
	const classes = useStyles();
	const { logo, _rawContent, cardAccentColor } = card;
	return (
		<Grid
			item
			xs={12}
			md={8}
			lg={4}
			container
			direction='column'
			justifyContent='center'
			alignItems='center'>
			<Card className={classes.root} elevation={0}>
				<CardContent
					style={{
						borderTop: `16px solid ${cardAccentColor.hexValue ?? '#002D5C'}`,
						minHeight: '210px',
						display: 'flex',
						flexDirection: 'column',
						padding: '24px',
						alignItems: 'space-evenly',
						justifyContent: 'space-evenly',
					}}>
					<GatsbyImage
						image={logo?.asset.gatsbyImageData}
						imgStyle={{ objectFit: 'contain' }}
						style={{ marginBottom: '1rem' }}
						alt="route manager products logo"
					/>
					<PortableText
						content={_rawContent}
						serializers={{
							normal: ({ children }) => (
								<Typography variant='body1'>
									{children}
								</Typography>
							),
							li: ({ children }) => (
								<Grid item>
									<Typography variant='body1'>
										<li style={{ listStyleType: 'none', marginBottom: '10px' }}>
											<Grid container>
												<Grid item xs={1}>
													<FontAwesomeIcon
														icon={['fas', 'circle-check']}
														size='lg'
														style={{
															color: cardAccentColor.hexValue ?? '#002D5C',
															marginLeft: '-8px',
														}}
													/>
												</Grid>
												<Grid item xs={11}>
													{children}
												</Grid>
											</Grid>
										</li>
									</Typography>
								</Grid>
							),
						}}
					/>
				</CardContent>
			</Card>
		</Grid>
	);
};
